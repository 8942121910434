<template>
  <div class="mb-5 mb-xl-0">
    <b-row v-if="selectedPeriod" class="no-gutters">
      <b-col>
        <ad-chart-periods :options="periods" v-model="selectedPeriodKey" valueField="key" textField="translation">
          <template #header><slot name="periods-header"></slot></template>
        </ad-chart-periods>
      </b-col>
    </b-row>
    <b-row class="no-gutters">
      <b-col>
        <ad-stock-chart
          :series="data"
          :labelDateFormatter="formatLabelDate"
          :labelValueFormatter="formatLabelValue"
          :tooltipDateFormatter="formatTooltipDate"
          :tooltipValueFormatter="formatTooltipValue"
          :pointInterval="selectedPeriod.pointInterval"
          :xAxisTickInterval="xAxisTickInterval"
          :xAxisLabelStep="xAxisStep"
          :xAxisMax="xAxisMax"
          :xAxisMin="xAxisMin"
          :height="chartHeight"
          :navigator="showNavigator"
          :scrollbar="showScrollbar"
          :breakThreshold="dayBreakThreshold"
          :repeatedBreaks="repeatedBreaks"
          :tickOffset="tickOffset"
          @marginChange="updateChartMargin"
          ref="chart"
        ></ad-stock-chart>
      </b-col>
    </b-row>
    <b-row class="pt-1 pt-md-3 no-gutters" :style="chartLegendMarginStyle" v-if="isLegendEnabled">
      <b-col :sm="selectedUnderlying ? 6 : 12">
        <ad-chart-legend :color="bidColor" :smColumns="selectedUnderlying ? 12 : 6">
          <template #header><slot name="second-legend-header"></slot></template>
          <template #first-header><slot name="second-legend-first-header"></slot></template>
          <template #first-values>
            <ad-product-data-value fieldKey="product.performance.dailyHigh"></ad-product-data-value>
            <ad-product-data-value fieldKey="product.performance.dailyLow"></ad-product-data-value>
          </template>
          <template #second-header><slot name="second-legend-second-header"></slot></template>
          <template #second-values>
            <ad-product-data-value
              fieldKey="product.performance.fiftyTwoWeeksHigh"
              v-if="!isProduct52WeeksHighLowHidden"
            ></ad-product-data-value>
            <ad-product-data-value
              fieldKey="product.performance.fiftyTwoWeeksLow"
              v-if="!isProduct52WeeksHighLowHidden"
            ></ad-product-data-value>
          </template>
        </ad-chart-legend>
      </b-col>
      <b-col sm="6" v-if="selectedUnderlying">
        <ad-chart-legend :color="underlyingColor">
          <template #header><slot name="legend-header"></slot></template>
          <template #first-header><slot name="legend-first-header"></slot></template>
          <template #first-values>
            <ad-underlying-data-value
              fieldKey="underlying.performance.dailyHigh"
              :underlyingItem="selectedUnderlying"
            ></ad-underlying-data-value>
            <ad-underlying-data-value
              fieldKey="underlying.performance.dailyLow"
              :underlyingItem="selectedUnderlying"
            ></ad-underlying-data-value>
          </template>
          <template #second-header><slot name="legend-second-header"></slot></template>
          <template #second-values>
            <ad-underlying-data-value
              fieldKey="underlying.performance.fiftyTwoWeeksHigh"
              v-if="!isUnderlying52WeeksHighLowHidden"
              :underlyingItem="selectedUnderlying"
            ></ad-underlying-data-value>
            <ad-underlying-data-value
              fieldKey="underlying.performance.fiftyTwoWeeksLow"
              v-if="!isUnderlying52WeeksHighLowHidden"
              :underlyingItem="selectedUnderlying"
            ></ad-underlying-data-value>
          </template>
        </ad-chart-legend>
      </b-col>
    </b-row>
    <b-row class="pt-2 no-gutters" :style="chartButtonsMarginStyle" v-if="!hideSwitches">
      <b-col>
        <ad-switch-button v-model="showAsk" activeStateClass="bg-ask" :disabled="!isIntraday">
          <slot name="button-ask"></slot>
        </ad-switch-button>
        <b-form-group
          v-if="settingsProperty.multipleUnderlyings"
          id="input-underlyings"
          label-for="underlyings-input"
          :class="[
            'mb-2',
            'pt-2',
            'align-bottom',
            'focus-white',
            { 'underlying-dropdown-inline': $screen.sm },
            { 'mr-1': $screen.sm },
          ]"
        >
          <ad-drop-down-list
            v-bind="$attrs"
            :options="productUnderlyingsOptions"
            :value="selectedUnderlyingIsin"
            @input="selectUnderlying"
            form-element-state-id="input-underlyings"
          />
        </b-form-group>
        <ad-switch-button
          v-else-if="settingsProperty.isUnderlyingButtonAvailable"
          :value="!!selectedUnderlyingIsin"
          @input="selectUnderlying"
          activeStateClass="bg-underlying"
        >
          <slot name="button-underlying"></slot>
        </ad-switch-button>
        <ad-switch-button v-model="relative">
          <slot name="button-relative"></slot>
        </ad-switch-button>
        <ad-switch-button v-model="realtime">
          <slot name="button-realtime"></slot>
        </ad-switch-button>
      </b-col>
    </b-row>
  </div>
</template>
<script lang="ts">
import { Component, Ref, Prop, InjectReactive, Watch } from 'vue-property-decorator';
import AdStockChart from '@components/molecules/charts/ad-stock-chart.vue';
import AdChartPeriods from '@components/molecules/charts/ad-chart-periods.vue';
import AdChartLegend from '@components/molecules/charts/ad-chart-legend.vue';
import { PriceModel, ProductModel, UnderlyingModel } from '@/src/types/the-q-api';
import { DataSeries, DropDownItem, LabelPosition } from '@/src/types/vue-api';
import { AdChartBase } from '@components/molecules/charts/ad-chart-base';
import { ProductChartSettings } from '@/src/types/episerver-api';
import { ChartColors, ChartSeries, DropDownItemType, InstrumentOriginTimeZone } from '@/src/types/enumerations';
import { utcParse } from '@/src/utils/date-helper';
import { Action, Getter } from 'vuex-class';

@Component({
  components: {
    'ad-stock-chart': AdStockChart,
    'ad-chart-periods': AdChartPeriods,
    'ad-chart-legend': AdChartLegend,
  },
})
export default class AdProductChart extends AdChartBase {
  @Ref() readonly chart!: AdStockChart;
  @InjectReactive() product!: ProductModel | null;
  @Prop({ default: false }) disableUnderlying!: boolean;

  @Getter('teaserUnderlyings', { namespace: 'underlying' })
  teaserUnderlyings!: Array<UnderlyingModel>;

  @Action('loadTeaserUnderlyingsAsync', { namespace: 'underlying' })
  loadTeaserUnderlyingsAsync!: (isins: string[]) => Promise<void>;

  @Action('subscribePushValue', { namespace: 'product' })
  subscribePushValue!: (key: string) => Promise<void>;

  created() {
    this.showAsk = (this.settingsProperty as ProductChartSettings).askButtonState;
    this.selectUnderlying((this.settingsProperty as ProductChartSettings).underlyingButtonState);

    this.subscribePushValue('product.price.bid.amount');
    this.subscribePushValue('product.price.ask.amount');
    this.subscribePushValue('product.price.timeStamp');

    this.$watch('product.price.bid.amount', this.pushBidChart);
    this.$watch('product.price.ask.amount', this.pushAskChart);
    this.$watch('teaserUnderlyings', this.pushUnderlyingPriceChart, { deep: true });

    super.created();
  }

  priceFormat(axis: LabelPosition): string {
    switch (axis) {
      case LabelPosition.Left:
        return (this.settingsProperty as ProductChartSettings).productTooltipValueFormatFieldKey || 'product.last';
      case LabelPosition.Right:
        return this.settingsProperty.tooltipValueFormatFieldKey || 'underlying.last';
    }
  }
  currencyCode(axis: LabelPosition): string {
    switch (axis) {
      case LabelPosition.Left:
        return this.product?.currencyCode || '';
      case LabelPosition.Right:
        return this.selectedUnderlying?.currencyCode || '';
    }
  }

  private get bidColor(): ChartColors {
    return ChartColors.productBid;
  }

  private get askColor(): ChartColors {
    return ChartColors.productAsk;
  }

  private get underlyingColor(): ChartColors {
    return ChartColors.underlying;
  }

  private get isProduct52WeeksHighLowHidden() {
    return (this.settingsProperty as ProductChartSettings).isProduct52WeeksHighLowHidden || false;
  }

  private get isLegendEnabled() {
    return (this.settingsProperty as ProductChartSettings).isLegendEnabled;
  }

  @Watch('product')
  protected async loadChartData() {
    if (!this.product) return;
    const [productData, underlyingData, productInitialData, underlyingInitialData] = await Promise.all([
      this.loadProductData(),
      this.loadUnderlyingData(),
      this.loadProductInitialData(),
      this.loadUnderlyingInitialData(),
    ]);

    this.initialValueForRelativeData = { ...productInitialData, ...underlyingInitialData };
    let data = [productData, underlyingData].reduce(
      (previousValue, currentValue) => (currentValue ? [...previousValue, ...currentValue] : previousValue),
      []
    );
    //If there is no data for product, series have to be filtered out for correct rendering. But only if push not activated.
    if (this.relative && data.find((s) => s.labels == LabelPosition.Left && s.data.length !== 0) === undefined) {
      data = data.filter((s) => s.labels == LabelPosition.Right);
      data.forEach((s) => (s.labels = LabelPosition.Left));
    }
    this.data = data;
  }

  async loadProductData(): Promise<DataSeries[]> {
    if (!this.product?.isin) return [];
    const action = 'GetProduct' + (this.relative ? 'Relative' : '');
    const series = this.getProductSeries();
    const data = await this.requestChartData(
      action,
      this.product.origin.timeZone == InstrumentOriginTimeZone.AET
        ? this.product.securityCode ?? this.product.isin
        : this.product.isin,
      this.product.origin.timeZone,
      series
    );
    const result: DataSeries[] = [];

    if (data && data[ChartSeries.bid]) {
      result.push(new DataSeries(ChartSeries.bid, data[ChartSeries.bid], ChartColors.productBid, LabelPosition.Left));
    }
    if (data && data[ChartSeries.ask]) {
      result.push(new DataSeries(ChartSeries.ask, data[ChartSeries.ask], ChartColors.productAsk, LabelPosition.Left));
    }
    return result;
  }

  async loadUnderlyingData(): Promise<DataSeries[]> {
    if (!(this.selectedUnderlying && this.selectedUnderlying.isin && this.product)) {
      return [];
    }

    const action = 'GetUnderlying' + (this.relative ? 'Relative' : '');
    const data = await this.requestChartData(
      action,
      this.product.origin.timeZone == InstrumentOriginTimeZone.AET
        ? this.selectedUnderlying.nsins.wkn ?? this.selectedUnderlying.isin
        : this.selectedUnderlying.isin,
      this.product.origin.timeZone
    );
    if (data && data[ChartSeries.price]) {
      const position = this.relative ? LabelPosition.Left : LabelPosition.Right;
      return [new DataSeries(ChartSeries.price, data[ChartSeries.price], ChartColors.underlying, position)];
    }
    return [];
  }

  private async loadProductInitialData(): Promise<{ [key: string]: number }> {
    if (!(this.relative && this.product)) {
      return {};
    }
    const series = this.getProductSeries();
    return this.requestInitialData(
      'GetProductInitialPrice',
      this.product.origin.timeZone == InstrumentOriginTimeZone.AET
        ? this.product.securityCode ?? this.product.isin
        : this.product.isin,
      this.product.origin.timeZone,
      series
    );
  }

  private async loadUnderlyingInitialData(): Promise<{ [key: string]: number }> {
    if (!(this.relative && this.selectedUnderlying && this.selectedUnderlying.isin && this.product)) {
      return {};
    }

    return this.requestInitialData(
      'GetUnderlyingInitialPrice',
      this.selectedUnderlying.nsins.isin,
      this.product.origin.timeZone
    );
  }

  private getProductSeries(): ChartSeries[] {
    const series = [ChartSeries.bid];
    if (this.showAsk) {
      series.push(ChartSeries.ask);
    }
    return series;
  }

  protected _ask = true;
  get showAsk(): boolean {
    return this.isIntraday && this.$data._ask;
  }
  set showAsk(value: boolean) {
    this.$data._ask = value;
    this.updateData();
  }

  selectedUnderlyingIsin = '';

  selectUnderlying(value: string | boolean): void {
    if (value === true && this.product && this.product.underlyings && this.product.underlyings.length > 0) {
      this.selectedUnderlyingIsin = this.product.underlyings[0].isin;
    } else if (typeof value == 'string') {
      this.selectedUnderlyingIsin = value;
    } else {
      this.selectedUnderlyingIsin = '';
    }
    if (
      this.selectedUnderlyingIsin &&
      this.product?.underlyings &&
      (!this.teaserUnderlyings || this.teaserUnderlyings.length == 0)
    ) {
      this.loadTeaserUnderlyingsAsync(this.product.underlyings.map((u) => u.isin));
    }
    this.updateData();
  }

  get selectedUnderlying(): UnderlyingModel | undefined {
    if (this.disableUnderlying) return undefined;
    const underlying = this.product?.underlyings?.filter((u) => u.isin === this.selectedUnderlyingIsin);
    return underlying && underlying?.length != 0 ? underlying[0] : undefined;
  }

  get productUnderlyingsOptions(): DropDownItem[] {
    const items =
      this.product?.underlyings?.map((option) => {
        return {
          type: DropDownItemType.item,
          text: option.name,
          value: option.isin,
        } as DropDownItem;
      }) || [];
    items.unshift({
      type: DropDownItemType.item,
      text: (this.settingsProperty as ProductChartSettings).noUnderlyingText,
      value: '',
    } as DropDownItem);
    return items;
  }

  pushBidChart(): void {
    const price = this.product?.price?.bid;
    const chartSerie = ChartSeries.bid;
    this.pushPriceChart(price, chartSerie);
  }

  pushAskChart(): void {
    if (!this.showAsk) {
      return;
    }
    const price = this.product?.price?.ask;
    const chartSerie = ChartSeries.ask;
    this.pushPriceChart(price, chartSerie);
  }

  private pushPriceChart(price: PriceModel | undefined, chartSerie: ChartSeries): void {
    if (!!price && !!this.product?.price?.timeStamp) {
      const pushValue = this.getChartPushValue(chartSerie, price.amount);
      this.pushChart(this.getSeriesIndex(chartSerie), pushValue, utcParse(this.product.price.timeStamp));
    }
  }

  pushUnderlyingPriceChart(): void {
    if (!this.selectedUnderlyingIsin) return;
    const underlying = this.teaserUnderlyings.find((u) => u.isin == this.selectedUnderlyingIsin);
    if (!underlying || underlying.last === null || underlying.timeStamp === null) return;
    const chartSerie = ChartSeries.price;
    const pushValue = this.getChartPushValue(chartSerie, underlying.last.amount);

    this.pushChart(this.getSeriesIndex(chartSerie), pushValue, utcParse(underlying.timeStamp));
  }

  getSeriesIndex(series: ChartSeries): number {
    return this.data.findIndex((ds) => ds.series === series);
  }

  pushChart(seriesIndex: number, pushValue: number | null, pushTimestamp: number): void {
    if (
      seriesIndex >= 0 &&
      //push the value if chart is already initialised, value has amount and timestamp
      this.realtime &&
      this.chart &&
      pushValue &&
      //if there is a min-max limitation for the xAsis, timestamp has to fit into that range
      (!this.xAxisMin || pushTimestamp >= this.xAxisMin) &&
      (!this.xAxisMax || pushTimestamp <= this.xAxisMax)
    ) {
      const updateThreshold = this.selectedPeriod?.pointInterval
        ? this.selectedPeriod?.pointInterval * 1000
        : undefined;
      this.chart.pushValue(seriesIndex, pushTimestamp, pushValue, updateThreshold, false);
    }
  }
}
</script>
<style lang="scss">
.bg-ask {
  background-color: $deep-red;
  label {
    color: $white;
  }
}

.bg-underlying {
  background-color: $black;
  label {
    color: $white;
  }
}

.underlying-dropdown-inline {
  display: inline-block;
  width: rem(277);
  height: rem(48);

  /* stylelint-disable selector-class-pattern */
  &.form-group .ad-drop-down-list .vs__actions {
    top: 0;
  }
  /* stylelint-enable selector-class-pattern */
}
</style>
